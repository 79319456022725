/* 
|-----------------------|
| CSS Código Gerado     |
|-----------------------|
*/

/* =-=-=-=-=-=-=-=-= Modal - Código Gerado -=-=-=-=-=-=-=-=-=- */
.modal-generated {
  max-width: 100vw;
}

.modal-generated .h4 {
  font-size: 2.0rem;
}

.gen-modal-row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

#form-source {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#container-code {
  background-color: #2c3e50;
  max-width: 70vw;
  max-height: 60vh;
  padding: 10px;
  overflow-x: hidden;
  position: relative;
  border-radius: 10px;
}

#generated-code {
  display: block;
  color: #fff;
}

.btn-copy-code {
  margin: 10px 0;
  width: 50%;
  height: 10%;
  background-color: #63c2ad;
}

.btn-copy-code:hover {
  background-color: #0092ad;

  transition: all 200ms linear;
}

.btn-copy-code.copied {
  background-color: #4BB543!important;
}