/* 
|-----------------------|
| CSS Global do projeto |
|-----------------------|
*/

/* =-=-=-=-=-=-=-= Modal -=-=-=-=-=-=-=-=- */
.modal-content {
  background-color: #3c414612;
  box-shadow: -10px -10px 10px 0 rgb(255,255,255), 14px 14px 14px 0 rgba(0,0,0,.1);
}

.modal-header {
  border-bottom: 0px solid white;
  justify-content: center;
}

.modal-produtos {
  max-width: 100vw;
} 

.psd-modal-row{
  display: flex;
  justify-content: center;
  font-size: 16pt;
  font-family: Arial, Helvetica, sans-serif;
}

.margin-prod {
  margin-bottom: 0.8rem;
}

.margin-prod.psd-modal-row {
  font-size: 14pt;
}

.btn-primary {
  border: 0;
  margin: 2px;
  height: 170px;
}

.btn-products {
  background-color: #6d757d;
  height: 35vh;
}

.btn-avancar {
  background-color: #63C2AD;
  border: 0;
  width: 100%;
  font-weight: 600;
  padding: 10px 0;

  transition: all 200ms ease-in;
}

.btn-avancar:hover {
  background-color: #00AF6F;
  color: #ffffff;
}

.product-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: max-content;
}

.modal-card {
  max-width: 100%;
  margin-top: -5px;
}

.img-thumbnail {
  border: 0;
  background: transparent;
  padding: .25rem;
  width: 242px;
  height: auto;
}

.field-label {
  display: block;
}

.btn-gen-code {
  height: 40px;
  margin: 10px 0;
  background-color: #6d757d;
  border-radius: 20px;
  width: 100%;
  color: #ffffff;

  transition: all 200ms ease;
}

.btn-gen-code:hover {
  background-color: #474747;
  color: #ffffff;
}

/* Input com margem */
.margin-field {
  margin: 5px 0;
}


/* =-=-=-=-=-=-=-= Estilização personalizada dos produtos -=-=-=-=-=-=-=-=- */
/* SPE */
.SPE, #btn-SPE:hover, #btn-SPE.active {
  background-color: #e96b1f;
}

.markSPE {
background-color: rgb(233 107 31 / 5%);
color: rgb(233 107 31 / 100%);
font-weight: 500;
font-size: 16pt;
}

.footerSPE {
width: 100%;
background-color: #e96b1f;
color: white;
text-align: center;
padding: 7px;
}

/* Conquista */
.Conquista, #btn-Conquista:hover, #btn-Conquista.active {
  background-color: #e0034d;
}

.markConquista {
  background-color: rgb(224 3 77 / 5%);
  color: rgb(224 3 77 / 100%);
  font-weight: 500;
  font-size: 16pt;
}

.footerConquista {
  width: 100%;
  background-color: #e0034d;
  color: white;
  text-align: center;
  padding: 7px;
}

/* PES */
.PES, #btn-PES:hover, #btn-PES.active {
  background-color: #0a467d;
}

.markPES {
  background-color: rgb(10 71 125 / 5%);
  color: rgb(10 71 125 / 100%);
  font-weight: 500;
  font-size: 16pt;
}

.footerPES {
  width: 100%;
  background-color: #0a477d;
  color: white;
  text-align: center;
  padding: 7px;
}