/* 

|-----------------------|
|   CSS do Gerador      |
|-----------------------|

*/

/* Logo/Header do produto escolhido */
#logo-header-col {
  display: flex;
  justify-content: center;
}

img {
  margin: 10px 0;
  width: 30%;
  height: auto;
}

/* Corpo do Gerador */
.body-gerador {
  background-color: rgb(10 71 125 / 4%);
}

.content-generator {
  margin-bottom: 50px;
}

.pageTitle {
  margin: 40px 16px;
  display: flex;
  justify-content: center;
}

.pageTitle h1 {
  font-size: 3rem!important;
  font-family: sans-serif;
}

.description {
  margin: 20px 15px;
  font-size: 16pt;
}

.content-page {
  margin: 15px 0;
}


/* =-=-=-=-=-=-=-=-= Campos Selecionáveis -=-=-=-=-=-=-=-=-=- */
#choose-fields {
  display: flex;
  flex-direction: column;
  border-right: 3px solid rgb(109 117 125 / 21%);
  vertical-align: middle;
}

.choose-field {
  margin: 5px 0;
  padding: 10px;
  border: 1px solid #ced4da;
  background-color: white;
  border-radius: .25rem;
  border-radius: 0px 0.25rem .25rem 0px;
  border-left-color: #6c757d;
  border-left-width: 8px;
  font-weight: 600;
  padding: 16px 15px 0px 20px;
}

.choose-field:hover {
  cursor: pointer;
}


/* =-=-=-=-=-=-=-=-=-=-= Campos Selecionados -=-=-=-=-=-=-=-=-=-=- */
.accordion-btn {
  color: #000000;
  font-weight: bold;
}

#selected-fields {
  border-right: 3px solid rgb(109 117 125 / 21%);;
}

.selected-field, .default-field {
  padding: 5px 0;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  max-height: 300px;
  position: relative;
  padding: 5px;
}

.selected-field:hover, .default-field:hover {
  cursor: pointer;
}

.form-check {
  padding: .375rem 1.75rem;
}

label.form-label {
  margin-top: 5px;
}

.title-row {
    text-align: center;
    background-color: #6d757d;
    border-radius: 20px;
    padding: 5px;
    margin: -17px 15% 8px;
    color: white;
}

.title-row h1 {
  font-size: 18px;
}

.editLabel {
  border: none;
}

.editLabel::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1; /* Firefox */
}

.editLabel:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: black;
}

.editLabel::-ms-input-placeholder { /* Microsoft Edge */
  color: black;
}


.custom-options-group {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100px;
}

.custom-options-group .option-row {
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

.add-icon {
  padding: 3px 5px;
  border-radius: 5px;

  transition: all 200ms ease;
}

.add-icon:hover {
  background: #6c757d;
  color: #fff;
}



.format-group {
  display: flex;
  justify-content: center;
}

.formats-icon {
  height: 20px!important;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #ffffff!important;
  color: #6c757d;
}

.formats-icon:hover {
  background-color: #6c757d!important;
  color: #ffffff;
}

.active {
  color: #6c757d!important;
  border: 1px solid #6c757d!important;
}

.formats-icon input {
  visibility: hidden;
}

/* margin do preview e selecionados */
@media (max-width: 768px) {
  #selected-fields, #form-preview {
    margin-top: 20px;
  }
}
